$(document).ready(function() {
  $("#id_date, .dateinput").pickadate({
    format: "yyyy-mm-dd",
    formatSubmit: "yyyy-mm-dd",
    hiddenName: true
  });

  $(
    ".form-group input:not(.btn), .form-group textarea, .form-control select"
  ).addClass("form-control");
});
